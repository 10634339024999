body.modal-open {
  overflow: hidden;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 92px 15px 29px 15px;
  max-width: 780px;
}

.tonConnectButton {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 57px;
}

.tonConnectButton button {
  text-transform: lowercase;
  background-color: #449BF1;
  justify-content: center;
  border-radius: 15px;
  padding: 16px;
  width: 100%;
  height: 50px;

}

.tonConnectButton button div {
  font-size: 20px;
  font-weight: 700 !important;
}

.tonConnectButton svg {
    display: none;
}

.event-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  padding: 17px 22px 23px;
  font-weight: 700;
  background-size: 100% 100%;
  background-image: url("img/newDarkGradient.png");
  background-position: left bottom;
  background-repeat: no-repeat;
}

.event-box-text {
  font-size: 95px;
  line-height: 0.8;
}

.btn {
  outline: none;
  padding: 13px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: rgb(var(--accent));
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  background-color: rgb(var(--element));
  
}

.form-btn-next {
  width: 100%;
}

.buttons-box {
  display: flex;
  gap: 17px;
  margin-bottom: 17px;
}

.link-button {
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  background-color: rgb(var(--container));
}



.grow {
  flex-grow: 1;
}

.media-container {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.media-container .our-media {
}

.media-container .github {  
  color: #449BF1;
}

.media-container .docs {
  color: #449BF1;
}

.arrow {
  display: block;
  cursor: pointer;
  width: 55px;
  height: 41px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}

.arrow-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  width: 100%;
}

.arrow-back {
  margin-bottom: 20px;
  background-image: url("./img/backArrow.svg");
}

.arrow-next {
  background-image: url("./img/nextArrow.svg");
}


.collection_image_container {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgb(var(--container));
}

.collection_image_container.info {

  overflow: hidden;
  border-radius: 13px;
  width: 141px;
  height: 141px;
  min-width: 141px;
  border: none;
  background-color: #2D3444;
}

.collection_image_container .img {
  min-width: 200px;
  min-height: 200px;
  max-width: max-content;
}

.collection_image_container.info .img {
  min-width: 141px;
  min-height: 141px;
  max-width: max-content;
}

.input-btn {
  width: max-content;
}

.form-item-label {
  display: inline-block;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  margin-bottom: 3px;
  padding-left: 10px;
  color: rgb(var(--element));
  font-weight: 600;
  font-size: 16px;
}

.logo-label-box {
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
}

.logo-label-box .form-item-label {
  padding: 0;
  margin: 0;
  width: 200px;
}



.form-input {
  display: block;
  padding: 13px 10px;
  border-radius: 10px;
  height: 53px;
  width: 100%;
  color: rgb(var(--accent));
  font-weight: 400;
  font-size: 16px;
  background-color: rgb(var(--container));
  border: none;

}

.div-for-input {
  display: block;
  border: 2px solid #449BF1;
  border-radius: 15px;
  height: 53px;
  width: 100%;
}

.form-input[type="datetime-local"]::-webkit-inner-spin-button,
.form-input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: none; 
}


.form-input:focus {
  outline: none !important;
  color: white;
}
/* 
.checkbox {
  color: #449BF1;
  font-weight: 600;
  font-size: 20px;
} */

/* .checkbox.checked {
  color: white;
} */

.input-checkbox {
  display: flex;
  align-items: center;
  height: 30px;
  padding-right: 40px;
  cursor: pointer;
  color: #449BF1;
  font-weight: 600;
  font-size: 20px;
  background-image: url('./img/inputInactive.svg');
  background-position: right center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.input-checkbox.checked {
  color: white;
  background-image: url('./img/inputActive.svg');
}

.square-btn {
  position: absolute;
  z-index: 10;
  right: 10px;
  cursor: pointer;
  border-radius: 10px;
  bottom: 11.5px;
  width: 30px;
  height: 30px;
  border: 2px solid #449BF1;
  
}

.square-btn.active {
  background-color: #449BF1;
}




.with-square {
  padding-right: 50px;
  padding-left: 7px;
  background-image: url('./img/inputInactive.svg');
  background-position: calc(100% - 10px) center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.with-square:focus, .with-square:valid, .with-square.active {
  background-image: url('./img/inputActive.svg');
}

.with-square.subscriptions_initial {
  background-image: url('./img/inputInactive.svg') !important;
}

.form-box {
  margin-bottom: 17px;
}

.form-item {
  display: block;
  margin-bottom: 5px;
}

.dataLoading {
  margin-bottom: 30px;
}

.displaynone {
  display: none;
}

/* 
.form-input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  background-color: transparent;
  border: 2px solid #449BF1;
}


.form-input:focus-visible {
  background-color: transparent !important; 
}


.form-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: white !important;
  background-color: transparent !important;
}

.form-input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
} */


.collection_description_container {  
 
  display: flex;
  margin: 0 auto 27px;
  gap: 20px;
    
}

/* @media screen and (max-width: 420px) {
  .collection_description_container {
    flex-direction: column;
  }
}

@media screen and (min-width: 421px) {
  .collection_description_container {
    flex-direction: row;
  }
} */


.collection_summary {
  position: relative;
  word-break: break-word;
  max-height: 141px;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.collection_title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
 

}

.more-less-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 5px;
  cursor: pointer;
  color: #007AFF;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.collection_description {
  color: rgb(var(--gray-symbols));
  font-size: 16px;
  font-weight: 400;
}

.collection_description.description.show-more:not(.expanded)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(transparent, rgb(var(--background-primary)));
  pointer-events: none;
}

.calendar-invite-container {
  margin-bottom: 15px;

}

.calendar-invite-container .start-date {
  margin-bottom: 7px;
  vertical-align: middle;
  width: 68px;
  height: 36px;
  display: inline-block;
  padding-top: 12px;
  text-align: center;
  margin-right: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("./img/calendar.svg");
}




.calendar-invite-container .invites {
  vertical-align: middle;
  height: 24px;
  width: 33px;
  padding-top: 7px;
  padding-left: 25px;
  display: inline-block;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("./img/human.svg");
}

.submit-btn { 
  display: block;
  cursor: pointer;
  margin: 0;
  padding: 13px 10px;
  border: 2px solid #449BF1;
  border-radius: 15px;
  width: 100%;
  color: white;
  text-align: center;  
  font-size: 24px;  
  font-weight: 600;
  text-transform: lowercase;
  background-color: transparent;
}

.submit-btn.is-connect {   
  border: none;
  background-color: #449BF1;
}

.claim-button {
  
}



.countdown-timer {
  margin-bottom: 34px;
  text-align: center;
  font-size: 20px;
 
}

.countdown-timer .timer {
  color: white;
  font-weight: 500;
  
}

.countdown-timer .label {
  color: rgb(var(--element));
  margin-bottom: 25px;
  font-weight: 400;
}





.subscriptions_initial {
  color: #6779A6;
  font-style: italic;
  font-size: 14px;
  height: 53px;
}

.default-subscription {
  color: white;
}

.no-select {
  outline: none;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.connect-message {
  margin-bottom: 30px;
  text-align: center;
}





.popup-window .popup-message {
  font-weight: 400;
  font-size: 14px;
  color: rgb(var(--accent));
  margin: 0 auto;
  text-align: center;
}

.popup-window {
  /* position: sticky;
  top: 0;
  z-index: 100; */
  margin-bottom: 10px;
 
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(var(--container))
}

.popup-window.popup-window-error {
  border: 1px solid #F02D3E
}

.popup-errors-box {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.wallet-pop-up {
  position: absolute;

}



.home-container {
  position: relative
}

.for-button-click:active {
  padding: 5px;
}

.for-button-click.event-btn {
  height: 205px;
  margin-bottom: 17px;
}

.for-button-click.link-btn {
  height: 108px;
}

.for-button-click.about-btn {
  height: 55px;
}

.about-button-box {
  margin-bottom: 15px;
}


.main {
  position: relative;
}

.subscription-input {
  padding-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 16px;
  height: 50px;
  margin-bottom: 10px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  background-color: #454D55;
  border: none;

}


.readonly-input, .readonly-input:focus, .input-count-btn.readonly-input, .input-count-btn.readonly-input:focus {
  color: #898e92;
  cursor: not-allowed;
}

.readonly-input-field, .channel-input.readonly-input-field, .channel-input.readonly-input-field:focus, .subscription-field.readonly-input-field {
  cursor: not-allowed;
  color: rgb(var(--gray-symbols));
}

.datetime-readonly-input {
  cursor: not-allowed !important;
}

/* .readonly-subscription {
  cursor: not-allowed;
} */

.modal-window {
  max-width: 500px;
  padding: 38px 15px 20px;
  border-radius: 10px;
  color: rgb(var(--accent));
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: rgb(var(--container));
}




.modal-overlay {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: rgba(15, 15, 15, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay.blurred {
  backdrop-filter: blur(5px);
  z-index: 8000;
}

.modal-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-button {
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 16px;
  width: 100px;
  text-align: center;
  background-color: transparent;
  
  color: rgb(var(--element));
}

.modal-button:hover {
  background-color: #42474b;
}

.modal-message {
  margin-bottom: 20px;
  color: rgb(var(--accent));
  font-size: 16px;
}

.modal-message.timezone-label {
  color: rgb(var(--element))
}

.modal-button.leave {
  color: rgb(var(--error-text));
}



.input-field.active-field {
  color: rgb(var(--accent))
}

.input-field.input-error, .time-field.input-error .time-field-value {
  color: rgb(var(--error-text));
}

/* .popup-window-success .popup-message, .popup-window-success .popup-close {
  color: #1f4f2b;
} */

/* .popup-window-success {
  color: #1f4f2b;
  background-color: #8BB492;
  border: none;
} */

/* .error, .error:focus {
  background-color: #ff57328a !important;
} */
/* 
.success, .success:focus {
  border: 2px solid #1f4f2b !important;
  color: #1f4f2b !important;
    background-color: #8BB492 !important;
} */

.error-message {
  color: rgb(var(--error-text));
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: -7px;
  margin-left: 3px;
}

/* .check-input:valid {
  border: 2px solid #1f4f2b;
  color: #1f4f2b;
  background-color: #8BB492;
} */


.check-input.with-square {
  padding-right: 50px;
  padding-left: 7px;
  background-position: calc(100% - 10px) center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

/* .check-input.with-square:valid {
  background-image: url('./img/inputActiveSuccess.svg');
} */

.check-input.with-square:invalid {
  background-image: url('./img/inputActiveError.svg');
}


.check-input.with-square.error, .check-input.with-square.error:focus {
  background-image: url('./img/inputActiveError.svg');
}

.check-input.with-square:invalid {
  background-image: url('./img/inputActiveError.svg');
}

.check-input.with-square {
  background-image: url('./img/inputActive.svg');
}

.check-input:invalid {
  background-color: #ff57328a;
  /* color: #FF370B; */
}

.input-count-btn.check-input:valid {
  border: none;
}

.input-count-btn.check-input:invalid {
  border: none;
  background: #FF370B;
}

.popup-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-close {
  cursor: pointer;
  color: rgb(var(--background-primary));
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 3px;
  border: 2px solid;
  height: 25px;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;  
}

.get-btn-inactive {
  cursor: not-allowed
}

.overlay-window {
  position: fixed; /* Зафиксировать на экране */
  top: 0;
  left: 0;
  width: 100%; /* На весь экран */
  height: 100%; /* На весь экран */
  padding: 10px;
  background-color: rgba(15, 15, 15, 0.7);
  color: white; /* Цвет текста */
  display: flex; /* Использовать Flexbox для центрирования */
  align-items: center; /* Вертикальное центрирование */
  justify-content: center; /* Горизонтальное центрирование */
  z-index: 7000; /* Перекрывает все другие элементы */
}

.overlay-window-message {
  height: 71px;
  padding-left: 82px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  background-image: url('./img/timer.svg');
  background-position: left center;
  background-size: 71px 71px;
  background-repeat: no-repeat;
}

.input-apply-btn {
  
  width: 100%;
  height: 50px;
  background-color: #449BF1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
}

.fixed-apply-btn-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 7px;
}

.input-field-container {
  margin-bottom: 9px;
  padding: 12px 15px 21px 10px;
  border-radius: 10px;
  height: 83px;
  background-color: rgb(var(--container));
}

@media screen and (max-width: 330px) {
  .input-field-label {
    font-size: 14px;
  }
}

@media screen and (min-width: 331px) {
  .input-field-label {
    font-size: 16px;
  }
}

.input-field-label {
  display: block;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  margin-bottom: 16px;
  font-weight: 500;
  color: rgb(var(--element));
}

.input-field {
  color: rgb(var(--gray-symbols));
  font-size: 16px;
  height: 22px;
  width: 100%;
  font-weight: 400;
  background-color: transparent;
  border: none;
}

.input-field:focus {
  outline: none !important;
}

.input-content {
  display: flex;
  align-items: center;
}

.plus-svg {
  cursor: pointer;
  margin-right: 5px;
}

.top-popup-window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: calc(100% - 20px);
  margin: 10px;  
}

.account-input-field-box {
  margin-bottom: 70px;
}

.input-field-error {
  color: rgb(var(--error-text));
  font-size: 13px;
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: 7px;
  padding-left: 7px;
}

.readonly-btn, .btn.readonly-btn {
  color: #17212B;
  background-color: rgb(var(--gray-symbols));
  cursor: pointer;
}




.comission-box {
  margin: 0 auto 40px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 201px;
  height: 50px;
  border-radius: 10px;
  border: 2px solid rgb(var(--element));
  background-color: rgb(var(--background-primary));
  font-weight: 500;
}

.comission-usd {
  margin: 0;
  color: #6779A6;
  font-size: 10px;
  text-align: right;
}

.comission-ton {
  margin: 0;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
}

.comission-box-label {
  margin: 0;
  font-size: 16px;
}

.copy-window {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: 10px;
  
}

.copy-window .popup-message {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgb(var(--accent));
}

.copy-window-content {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(var(--container));
}