.form-item.with-use-different {
    position: relative;

}

/* .use-different {
    position: absolute;
    z-index: 1;
    top: 39px;
    right: 47px;
    color: #449BF1;
    font-weight: 600;
    font-size: 16px;

} */

.use-different {
    padding-right: 50px;
    color: #449BF1;
    font-weight: 600;
    font-size: 16px;
    text-align: right;
}

.total-minted-owners {
    margin-bottom: 44px;
    font-size: 24px;
    font-weight: 600;
    color: #449BF1;
}