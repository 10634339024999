.profile-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    font-family: 'Inter';
}

.profile-box .avatar {
    border-radius: 50%;
    margin-bottom: 16px;
}

.profile-box .firstname {
    font-size: 24px;
    font-weight: 600;
    color: rgb(var(--accent));
}

.profile-box .username {
    font-size: 16px;
    font-weight: 600;
    color: rgb(var(--gray-symbols));
}

.referral-btn {
    cursor: pointer;
    display: block;
    color: rgb(var(--accent));
    padding: 13px;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    background-color: rgb(var(--container));
    margin-bottom: 30px;
}

.referral-box {
    padding: 12px 8px 24px;
    border-radius: 10px;
    background-color: rgb(var(--container));
    margin-bottom: 15px;
}

.referral-box-item {
    padding: 0 7px;
}

.referral-item-label {
    display: inline-block;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    padding-right: 23px;
    padding-top: 3px;
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(var(--element));
    background-image: url("../../img/i.svg");
    background-repeat: no-repeat;
    background-position: right center;

}


.referral-box-item-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    margin-left: 13px;
    padding: 7px 7px 7px 12px;
    font-weight: 400;
    font-size: 16px;
    color: rgb(var(--accent));
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 2px solid rgb(var(--element));
    background: #0F0F0FB2;
}

.referral-box-line {
    margin-top: 24px;
    margin-bottom: 15px;
}

.referral-copy-img {
    cursor: pointer;
    float: right;
}

.indicator-field-info {
    color: rgb(var(--gray-symbols));
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}

.indicator-field {
    position: relative;
    overflow: hidden;
    height: 51px;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(var(--gray-symbols));
    margin-bottom: 8px;
    background-color: rgb(var(--container));
}

.indicator-field-background {
    display: block;
    background-color: rgb(var(--element));
    width: 100%;
    height: 100%;
}

.indicator-field-text {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.indicator-field-text.active {
    color: rgb(var(--accent));
}

.indicator-field-box .referral-item-label {
    margin-bottom: 5px;
    padding-left: 15px;
}

.indicator-field-box {
    margin-bottom: 50px;
}

.discount-text {
    padding-left: 7px;
    font-weight: 500;
    font-size: 16px;
}

.balance-box {
    text-align: center;
    margin-bottom: 33px;
}

.balance-value {
    color: rgb(var(--accent));
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
}

.balance-helper-text {
    color: rgb(var(--gray-symbols));
    font-size: 16px;
    font-weight: 400;
}

.collection-summary-box {
    display: flex;
    align-items: center;
    color: rgb(var(--gray-symbols));
  }

.collection-summary-box.edit-mode {
    color: rgb(var(--accent))
}

.collection-icon {
    border-radius: 5px;
    overflow: hidden;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-color: rgb(var(--gray-symbols));
}

.edit-icon {
    cursor: pointer;
}

.collection-name {
    flex-grow: 1;
}

.create-collection-btn {
    width: 100%;
    display: block;
    margin-bottom: 30px;
}

.modal-window.collection-edit-modal {
    position: relative;
    width: 100%;
    padding: 24px 10px 15px;
    border-radius: 10px;
    border: 2px solid rgb(var(--gray-symbols));
    background-color: rgb(var(--background-primary));
}

.collection-edit-container {
   
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collection-edit-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.edit-collection-btn {
    display: block;
    width: 100%;
}

.btn.withdraw-btn {
    height: 50px;
    width: 176px;
    margin: 0 auto 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-field-label {
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;
}

.onboarding-open-account-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}