.onboarding-container {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 15px 23px;
    background-color: #1B3450;

}

.onboarding-content {
    height: calc(100% - 50px);
}

.onboarding-arrows-box {
    position: absolute;
    top: calc(50% - 35px);
    width: 100%;
}

.gif-indicator {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    height: 3px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 20px;
}
.gif-indicator-item {
    width: 20%;
    background-color: black;    
    border-radius: 10px;
}

.gif-indicator-item.preview {
    background-color: white;
}

.onboarding-animated-indicator {
    display: block;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    
}

.gif-label {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    height: 50px;
    margin-bottom: 15px;
}

.onboarding-arrow-next {
    cursor: pointer;
    position: absolute;
    right: 20px;
    padding: 15px;
}

.onboarding-arrow-back {
    cursor: pointer;
    position: absolute;
    left: -25px;
    padding: 15px;
}

.btn.onboarding-skip-btn {
  font-size: 16px;
  font-weight: 600;
  width: 72px;
  float: right;
  padding: 5px;
  position: absolute;
  right: 23px;
  bottom: 15px;
}

.gif-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    width: auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.onboarding-image {
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}

@supports (-webkit-touch-callout: none) {
    .video-container {
        height: calc(100% - 50px);
        width: auto;
        margin: 0 auto;
    }
    
    .onboarding-image {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}