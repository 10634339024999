.header {
    position: fixed;
    z-index: 99;
    top: 0;
    padding: 16px 0;
    width: calc(100% - 30px);  
    max-width: 750px;
    display: flex;
    justify-content: space-between;
    font-family: 'Inter';
    align-items: center;
    background-color: rgb(var(--background-primary));

}


.react-switch-bg {
    border: 2px solid;
}

.react-switch-off .react-switch-bg {
    border-color: #449BF1;
    cursor: pointer !important;
}

.react-switch-on .react-switch-bg {
    /* border-color: white; */
    border-color: rgb(var(--accent));
}

.react-switch-handle {
    

}

.react-switch-off {
    color: #449BF1;
    background: transparent;
    font-weight: 400;
}

.react-switch-on {
    cursor: pointer;
    color: rgb(var(--accent));
    background: rgb(var(--element));
    font-weight: 600;
}

.react-switch-container {
    position: relative;
    cursor: pointer;
    height: 40px;
    margin-right: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    padding-right: 10px;
    font-size: 14px;
}

.header-menu-icon {
    position: absolute;
    z-index: 501;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("../img/lkMenuIcon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 21px 12px;
}

.header-menu-icon.is-open {
    background-color: rgb(var(--container));
    background-size: 16px 16px;
    background-image: url("../img/crossIcon.svg");
    border-radius: 10px;
    background-position-y: 18px;
    background-position-x: center;
    height: 65px;
}

.header-menu-container {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 49px;
    margin-right: -15px;
    height: 49px;
}

.header-popup-menu .user-box {
    height: 60px;
    border-bottom: 1px solid #3A3F43;
    display: flex;
    gap: 15px;
    margin-left: -8px;
    align-items: center;
   
}

.header-popup-menu .user-box .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.header-popup-menu .user-box .username {
    font-weight: 500;
}

.header-popup-menu-item {
    height: 42px;
    display: flex;
    gap: 27px;
    align-items: center;
}

.header-popup-menu-item .item-label {
    font-weight: 400;
    cursor: pointer;
}

.header-popup-menu-item .icon {
    height: 23px;
    width: 23px;
}

.header-popup-menu {
    position: absolute;  
    z-index: 501;  
    right: 0;
    top: 51px;
    width: 200px;
    border-radius: 10px;
    color: rgb(var(--accent));
    font-size: 16px;
    padding: 0 20px 10px;
    background-color: rgb(var(--container));

}

.header .modal-overlay {
    position: absolute;
    z-index: 98;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    padding: 0;
}

.popup-menu {   
    position: absolute;
    right: 0;
    top: 45px;
    z-index: 10;
    color: black;
    border: 1px solid #ccc; 
    border-radius: 4px; 
    padding: 10px;
    background: white;    
}

.popup-menu-item {
    cursor: pointer;
    padding: 5px
}

.react-switch {
    opacity: 1 !important;
    padding-left: 5px;
    cursor: pointer;
}

.logo {   
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    padding-left: 15px;
}

.wallet-disconnect-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;

}

.disconnect-menu-disabled {
    cursor: default
}