@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular')
      url('fonts/inter/Inter-Regular.woff2') format('woff2'),
      url('fonts/inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light BETA'),
      url('fonts/inter/Inter-LightBETA.woff2') format('woff2'),
      url('fonts/inter/Inter-LightBETA.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
      url('fonts/inter/Inter-SemiBold.woff2') format('woff2'),
      url('fonts/inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
      url('fonts/inter/Inter-Bold.woff2') format('woff2'),
      url('fonts/inter/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
      url('fonts/inter/Inter-Medium.woff2') format('woff2'),
      url('fonts/inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
      url('fonts/roboto/Roboto-Bold.woff2') format('woff2'),
      url('fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
      url('fonts/roboto/Roboto-Bold.woff2') format('woff2'),
      url('fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
      url('fonts/roboto/Roboto-Regular.woff2') format('woff2'),
      url('fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
      url('fonts/roboto/Roboto-Medium.woff2') format('woff2'),
      url('fonts/roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}



:root {
  /* light theme tokens */
  --background-primary-light: 255, 255, 255;
  --accent-light: 41, 48, 64;
  --element-light: 41, 144, 255;
  --container-light: 33, 33, 33;
  --gray-symbols-light: 115, 126, 135;
  --error-text-light: 255, 25, 46;


  /* dark theme tokens */
  --background-primary-dark: 15, 15, 15;
  --accent-dark: 255, 255, 255;
  --element-dark: 41, 144, 255;
  --container-dark: 33, 33, 33;
  --gray-symbols-dark: 115, 126, 135;
  --error-text-dark: 255, 25, 46;

}

/* map tokens to proper theme */
[data-theme='light'] {
  --background-primary: var(--background-primary-light);
  --accent: var(--accent-light);
  --element: var(--element-light);
  --container: var(--container-light);
  --gray-symbols: var(--gray-symbols-light);
  --error-text: var(--error-text-light);
}

[data-theme='dark'] {
  --background-primary: var(--background-primary-dark);
  --accent: var(--accent-dark);
  --element: var(--element-dark);
  --container: var(--container-dark);
  --gray-symbols: var(--gray-symbols-dark);
  --error-text: var(--error-text-dark);
}

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	max-width: 100%;
}

h1, p {
  margin: 0;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', 'Inter', 'font', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--accent));
  background-color: rgb(var(--background-primary));
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
