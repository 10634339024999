
.load_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;   
}

.load-file-container {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #2D3444;
}

.collection_name_and_logo_box {
  display: flex;
  gap: 8px;
  margin-bottom: 9px;
}
.collection_name_and_logo_box > * {
  margin: 0 !important;
}

.collection_name_and_logo_box .input-field-container {
  flex-grow: 1;
}

.time-field {
  display: flex;
  gap: 13px;
  align-items: center;
  height: 60px;
  padding: 19px;
}

.time-field .time-field-value {
 
  color: rgb(var(--accent));
  font-size: 16px;
  height: 16px;
  font-weight: 500;
}

.time-field .time-field-icon {
  height: 22px;
}

.time-fields-box {
  margin-bottom: 23px;
  background-color: rgb(var(--container));
  border-radius: 10px;
}

.time-fields-box .time-field {
  cursor: pointer;
  border-bottom: 1px solid rgb(var(--gray-symbols));
}

.time-fields-box .time-field:last-child {
  border: none;
}



.choose-image-box {
  width: 200px;
  height: 200px;
}

.choose-image-icon.load-icon {
  background-image: url("../img/load.svg");
  top: -8px;
  background-size: 14px;
}

input[type="file"], input[type=file]::-webkit-file-upload-button { 
  cursor: pointer !important; 
} 

.choose-image-icon {
  position: absolute;
  right: -8px;
  z-index: 10;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #212121;
  background-position: center center;
  background-repeat: no-repeat;
  
  
}

.choose-image-icon.random-icon {
  background-image: url("../img/random.svg");
  bottom: -8px;
  background-size: 18px;
}

.images-container {
  display: flex;
  justify-content: center;
  gap: 9px;
  margin-bottom: 10px;
}

.load-images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.btn.random-btn {
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 251px;
}

.btn.input-count-btn {
  cursor: auto;
}

.form-input.count-input {
  cursor: auto;
  width: 109px;
  text-align: center;
}



.btn-box {
    display: flex;
    gap: 17px;
    margin-bottom: 11px;
    justify-content: center;
    /* justify-content: space-between;     */
}

.input-count-btn {
  width: 109px;
}



.margin-bottom-17 {
  margin-bottom: 17px !important;
}



.form-input.textarea {
  overflow: hidden;
}

.datetime-inputs-box {
  display: flex;
  justify-content: space-between;
}

.datetime-inputs-box .form-item {
  position: relative;
 
}

.datetime-inputs-box .form-input {
  width: 100%;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.form-input[type="datetime-local"] {
  color: transparent;
  height: 53px;
  padding-right: 50px;
}

.form-input[type="datetime-local"]:focus, .form-input[type="datetime-local"]:active {
  color: transparent !important;
  background-color: transparent !important;
  user-select: none !important;
}



.number-select-option {

}

.number-selector {
  cursor: pointer;
  text-align: center;
}



.number-select {
  margin: auto;
  -webkit-appearance: none; /* Убирает стандартные стрелки для Safari */
  -moz-appearance: none;    /* Убирает стандартные стрелки для Firefox */
  appearance: none;         /* Убирает стандартные стрелки для других браузеров */
  outline: none;           /* Убирает обводку при фокусировке */
  border: none;
  background-color: transparent; /* Цвет фона */
  
}

/* Скрытие полосы прокрутки */
.number-select::-webkit-scrollbar { 
  display: none; /* Для Chrome, Safari и Opera */
}

.number-select::-moz-scrollbar { 
  display: none; /* Для Firefox */
}

.number-selector-option:hover, .number-selector-option.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.number-selector-option {
  cursor: pointer;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.nft-text {
  color: #449BF1;
  width: 265px;
  margin: 0 auto 30px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  overflow-wrap: break-word; /* Для современных браузеров */
  word-wrap: break-word;     /* Совместимость с более старыми браузерами */
  word-break: normal;   
}


.qr-container {
  overflow: hidden;
  border-radius: 13px;
  width: 200px;
  height: 200px;
  border: 2px solid #449BF1;
  margin: 0 auto 42px  
}

.qr-container .img {
  min-width: 200px;
  min-height: 200px;
  max-width: max-content;
}

.btn.send-to-bot-btn {
  margin-bottom: 42px;
  width: 172px;
}

.form-input.textarea {
  height: 186px;
}

.form-input.calendar-input:focus {
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  color: transparent !important;
  background: transparent !important;
}


.datetime_formatted {
  position: absolute;   
  bottom: 18px;
  /* height: 53px;
  width: 100%; */
  display: flex;
  align-items: center;
  font-weight: 600;
  /* background:  gray; */
}


@media screen and (min-width: 350px) {
  .datetime_formatted {
    padding-left: 20px;
    font-size: 16px;
  }

  .datetime-inputs-box .form-item {
    width: 49%;  
  }
}

@media screen and (max-width: 350px) {
  .datetime_formatted {
    padding-left: 10px;
    font-size: 14px;
  }

  .datetime-inputs-box .form-item {
    width: calc(50% - 8px);  
  }
}

.options-flex {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
}



.options-list {
  padding: 0;
  display: inline-block;
  list-style-type: none;
  border-radius: 5px;
  max-height: 150px; /* Ограничиваем высоту списка */
  overflow-y: auto; /* Добавляем прокрутку при необходимости */
}
/* 
.number-selector-option {
  color: #000; 
  cursor: pointer; 
  padding: 10px;
}

.number-selector-option:hover {
  background-color: rgba(255, 255, 255, 0.2); 
} */


.input_clue {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #449BF1;
}

.minter-box {
  position: relative
}

.channel-avatar {
  position: absolute;
  margin-left: 10px;
  border-radius: 24px;
  width: 41px;
  height: 41px;
}

.close-keyboard {
  cursor: pointer;
  color: #449BF1;
  position: absolute;
  right: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;  

}
.close-keyboard.error {
  color: rgb(var(--error-text));
}

.channel-input.channel-with-avatar {
  padding-left: 57px;

}
.channel-input.new-input {
  background-image: url("../img/whitePlus.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  cursor: pointer;
  background-color: rgb(var(--element));
}

.form-input.password-input {
  padding-right: 50px;
}

.password-empty-input {
  background-color: #2D3444;
  border: none
}

.password-empty-input:focus {
  background-color: #2D3444;
} 

.channel-with-avatar-container {
  position: relative;
  display: block;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.channel-input {
  height: 53px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 0;
}

.calendar-open {
  cursor: pointer;
  height: 53px;
  font-size: 16px;
  padding-right: 50px;
  padding-left: 7px;
  display: flex;
  align-items: center;
}

.modal-overlay.modal-calendar {
  padding: 10px;
}

.modal-calendar .modal-window {
  padding: 40px 25px 25px 25px;
  width: 100%;
}

.calendar-selector-container {
  margin-bottom: 30px;
  max-height: 105px; /* Ограничение высоты для прокрутки */
  height: 105px;
  overflow-y: auto; /* Добавляем прокрутку при необходимости */
}

.calendar-selector-container .calendar-options-list {
  list-style-type: none;
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease; /* Добавляем плавный переход для прокрутки */
    display: flex;
    flex-direction: column; /* Вертикальная ориентация */
}

.calendar-selector-container.selector-day .calendar-selector-option {
  width: 55px;
}

.calendar-selector-container.selector-hours .calendar-selector-option, .calendar-selector-container.selector-minutes .calendar-selector-option {
  width: 70px;
}

.calendar-selector-container.selector-year .calendar-selector-option {
  width: 65px;
}

.calendar-selector-container.selector-month .calendar-selector-option {
  width: 130px;
}

.calendar-selector-option {
  outline: none;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  height: 35px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: lowercase;

  /* padding: 20px;  */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
/* Скрытие полосы прокрутки для WebKit-браузеров (Chrome, Safari) */
.calendar-selector-container::-webkit-scrollbar {
  display: none; /* Прячем полосу прокрутки */
}

/* Скрытие полосы прокрутки для Firefox */
.calendar-selector-container {
  scrollbar-width: none; /* Скрываем полосу прокрутки */
}

/* .option.hovered {
  background-color: rgba(0, 255, 0, 0.3);
} */

.calendar-selector-option.selected, .calendar-selector-option.selected.hovered  {
  
  border: 2px solid #449BF1;
  color: white;
  
}

/* .calendar-selector-option:hover {
  background-color: rgba(255, 255, 255, 0.2);
} */

.calendar-selectors {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.time-selectors {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendar-selector-label {
  display: inline-block;
  margin-bottom: 10px;
  color: #449BF1;
  font-weight: 600;
  font-size: 20px;
}

.calendar-time-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-calendar-window {
  max-width: 500px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  /* border-color: #1565c0; */
  color: rgb(var(--accent));
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  background: rgb(56 73 89);
}

.mui-calendar {
  color: white !important;
  width: 100% !important;
  max-width: 100%;
  padding: 0;
  
}

.clear-calendar {
  display: block;
  text-align: right;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #bbbbbb;
  margin-right: 12px;
}

.time-input-box {
  position: relative;
  padding: 0;
  width: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;

}

.select-time-label {
  font-size: 16px;
  font-weight: 400;
  color: #bbbbbb;
}

.mobile-time-picker {
  width: 120px !important;
  display: none !important
}

.form-input.mui-calendar-input {
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .modal-overlay.calendar-overlay {
  background-color: rgba(23, 33, 43, 0.9);
} */


.clear-or-apply-date-box {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 15px 10px;
  background: #151e27;
  z-index: 100
}

.clear-or-apply-btn {
  width: 50%;
  text-align: center;
  box-shadow: none !important;
}

.clear-or-apply-btn:hover {
  background-color: rgba(144, 202, 249, 0.08);
}

.time-icon-btn.css-1xs1fn5-MuiButtonBase-root-MuiIconButton-root {
  position: absolute;
  right: 0;
}

.images-container-item-label {
  display: block;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  margin-bottom: 5px;
  color: rgb(var(--element));
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}


.calendar-bottom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.load-file-container.is-selected {
  border: 2px solid rgb(var(--element));
}