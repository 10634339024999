.my-nfts-title {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(var(--element));
}

.my-nfts-container .events-box {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
}

.my-nfts-container .events-box .event-item {
    cursor: pointer;
    width: calc((100% -  22px) / 3);
    overflow: hidden;
    border-radius: 5px;
    background-color: rgb(var(--container));;
}

.event-item .event-info { 
    padding: 7px 5px;

}

.event-item .event-info .event-name { 
    font-size: 14px;
    font-weight: 500;
    color: rgb(var(--accent));
    margin-bottom: 2px;

}

.event-item .event-info .event-collection { 
    font-size: 10px;
    font-weight: 400;
    color: rgb(var(--gray-symbols));

}

.modal-window.nft-card-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 25px 22px 13px;
    border-radius: 10px;
    border: 2px solid rgb(var(--gray-symbols));
    background-color: rgb(var(--background-primary));
    max-height: 90vh;
    overflow-y: auto;
}

.nft-card-modal .logos-box {
    position: relative;
    display: flex;
    justify-content: center;
    width: 175px;
    height: 175px;
    min-height: 175px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 23px;
}

.nft-card-modal .logos-box .event-img-in-card {
    position: absolute;
    height: 100%;
    width: auto;
    object-fit: cover;
    border-top-left-radius: 12px;

}

.nft-card-modal .logos-box .collection-img-in-card-overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 10px;
}

.nft-card-modal .logos-box .collection-img-in-card {
    width: auto;
    height: 100%;
    object-fit: cover;
}

.my-nfts-container .event-item .event-logo-box {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.my-nfts-container .event-item .event-logo-box .img {
    object-fit: cover;
}

.nft-card-modal .collection-img-in-card-overlay {
    position: absolute;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 10px;
    left: 0;
}

.nft-card-modal .event-name-in-card {
    font-size: 24px;
    font-weight: 500;
    color: rgb(var(--accent));
    margin-bottom: 5px;
}

.nft-card-modal .event-info-in-card {
    text-align: left;
    flex-grow: 1;
    margin-bottom: 15px;
}

.event-info-in-card .event-info-string {
    line-height: 1.3;
    font-size: 14px;
    font-weight: 400;
    color: rgb(var(--gray-symbols));
}

.event-info-in-card .event-info-string.description-string {
    position: relative;
    max-height: 60px;
    overflow: hidden;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(var(--gray-symbols));
    transition: max-height 0.3s ease-out;
}

.event-info-in-card .event-info-string.description-string.expanded {
    max-height: 1000px; /* или другое большое значение */
}

.modal-window.nft-card-modal.expanded {
    
}

.nfts-card-more-less-button {
    position: absolute;
    bottom: 5px;
    right: 0;
    background: none;
    border: none;
    color: #449BF1;
    cursor: pointer;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1;
}

.event-info-string.description-string.expanded .nfts-card-more-less-button {
    bottom: 15px;
}

/* Добавьте градиент для скрытия текста */
.event-info-in-card .event-info-string.description-string.show-more:not(.expanded)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(transparent, rgb(var(--background-primary)));
    pointer-events: none;
}