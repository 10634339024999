.statistics-event-container {
    display: flex;
    gap: 9px;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 10px;
    background-color: rgb(var(--container));
}

.statistics-event-container .first-string {
    display: flex;
    justify-content: space-between;
}

.statistics-event-container .statistics-event-label {
    color: rgb(var(--element));
    font-weight: 500;
    font-size: 16px;
}

.statistics-event-container .open-icon {
    cursor: pointer;
}

.statistics-event-nft-indicator {
    height: 21px;
    margin-right: 24px;
    margin-bottom: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgb(var(--gray-symbols));
    font-size: 12px;
    font-weight: 400;
}

.statistics-event-nft-indicator.active {
    color: white;
}

.statistics-event-nft-indicator .statistics-indicator-field {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 2px solid rgb(var(--element));
    background-color: rgb(var(--background-primary));
    padding: 3px 9px;
    width: calc(100% - 34px);
}

.statistics-event-nft-indicator .ratio-text {

    height: 12px;
}

.statistics-event-nft-indicator .statistics-indicator-field .ratio-background {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(var(--element));
}

.statistics-event-nft-indicator .statistics-indicator-field .text {
    position: absolute;
    z-index: 1;
    height: 12px;
}

.statistics-content .event-status {
    margin-bottom: 10px;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.statistics-content .event-link {
    font-weight: 400;
    font-size: 16px;
    color: rgb(var(--element));
}

.statistics-empty-container {

}

.statistics-empty-text {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.statistics-empty-button {

}