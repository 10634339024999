.nfts_info_container {
    padding: 20px 14px;
    border-radius: 15px;
    border: 2px solid #449BF1;
    color: #449BF1;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 28px;
}

.nfts_count_span {
    color: white;
}

.nfts_info_p {
    margin-bottom: 20px;
}

.nft-logos-and-count-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
    margin-bottom: 4px;
}

.nft-count-label {
    font-weight: 600;
    color: #449BF1;
}

@media screen and (min-width: 350px) {
    .nft-count-label {
        font-size: 16px;
    }
}

@media screen and (max-width: 350px) {
    .nft-count-label {
        font-size: 12px;
    }
}



.nfts_pictures_container {
    display: flex;
    gap: 3px
}

.nfts-pictires-main {
    display: flex;
    gap: 3px;
}

.nfts-pictires-remaining {
    position: relative;
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
}

.nfts-pictires-remaining .nft_picture {
    position: absolute;
}

.nft_picture {
    overflow: hidden;
    width: 29px;
    height: 29px;
    border-radius: 7px;
    border: 2px solid #449BF1;
    background-color: rgb(var(--background-primary));
}

.nft-indicator {
    position: relative;
    overflow: hidden;
    height: 53px;
    width: 100%;
    border: 2px solid #449BF1;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin-bottom: 33px;
}

.indicator-background {
    display: block;
    background-color: #449BF180;
    width: 100%;
    height: 100%;
}

.indicator-text {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}