



.react-theme-switch {
    opacity: 1 !important;
    margin-bottom: 25px;
}

.react-theme-switch .react-switch-bg {
    border: 2px solid
}

.react-theme-switch .react-switch-bg {
    border-color: #449BF1;
}


.react-theme-switch .react-switch-handle {
    background-image: url("../img/sun.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

}