

  .collection_data .form-item {
    margin-bottom: 10px;
  }

  .subscription-field {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    height: 50px;
    border-top-right-radius: 10px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 50px;
    color: rgb(var(--accent));
    font-size: 16px;
    font-weight: 400;
    background-color: rgb(var(--container));
    background-image: url('../img/UnsubscribedCheckbox.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 13px);
    background-position-y: center;
    background-size: 25px 25px;
  }

  .subscription-field:last-child {
    margin-bottom: 0;
  }


  .subscription-field.active {
    background-image: url('../img/SubscribedCheckbox.svg');
  }

  .subscription-field-avatar {
    height: 100%;
    border-radius: 50%;
    margin-right: 20px;
  }

  .collection_subscription {
    padding-left: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 16px;
    height: 50px;
    margin-bottom: 10px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    background-color: #454D55;
    border: none;

  }

  .collection_subscription:last-child {
    margin-bottom: 0;
  }

  .collection_subscription_avatar {
    border-radius: 24px;
    width: 48px;
    height: 48px;
  }

  .collection_subscription .channel_label {

  }


  .collection_subscription.channel_link {
    background-color: #2D3444;
  }

  .subscription-list {

  }

  .select-subscriptions {
    cursor: "pointer";
    margin-bottom: 10px;
  }

  .collection_data_form {
    
  }

  
  
  .collection-get-button { 
    padding: 11px;
    width: 100%;
    margin-bottom: 93px;
  }

  .checker-box {
    position: relative;
  }

  .collection_subscription.with-square:valid, .collection_subscription.with-square:focus {
    background-image: url('../img/inputInactive.svg');
  }

  .collection_subscription.with-square.active {
    background-image: url('../img/inputActive.svg') !important;
  }
  
  .collection_subscription.channel_link.readonly-input {
    /* background-color: #353c43; */
    cursor: not-allowed;
    color: #898e92;
  }

  /* .collection_subscription.channel_link.readonly-input .channel_label {
    color: #2D3444;
  } */

  .onboarding-open-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }